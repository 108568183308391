import { createRouter, createWebHistory } from 'vue-router'
import Top from '../views/Top.vue'
import { authorizeToken } from './guards'

const routes = [
  {
    path: '/',
    name: 'Top',
    meta: { requiresAuth: true },
    component: Top
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: { requiresAuth: true },
    component: () => import('../views/Users.vue')
  },
  {
    path: '/deleted_users',
    name: 'DeletedUsers',
    meta: { requiresAuth: true },
    component: () => import('../views/DeletedUsersView.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    meta: { requiresAuth: true },
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/deleted_projects',
    name: 'DeletedProjects',
    meta: { requiresAuth: true },
    component: () => import('../views/DeletedProjectsView.vue')
  },
  {
    path: '/alert_forms',
    name: 'AlertForms',
    component: () => import('../views/AlertForms.vue'),
  },
  {
    path: '/alert_forms_create',
    name: 'AlertFormsCreate',
    component: () => import('../views/AlertFormsCreate.vue'),
  },
  {
    path: '/alert_forms_edit/:alert_form_id',
    name: 'AlertFormsEdit',
    component: () => import('../views/AlertFormsEdit.vue'),
  },
  {
    path: '/alert_forms_report/:alert_form_id',
    name: 'AlertFormsReport',
    component: () => import('../views/AlertFormsReport.vue'),
  },
  {
    path: '/user_setting',
    name: 'UserSetting',
    meta: { requiresAuth: true },
    component: () => import('../views/UserSettingView.vue')
  },
  {
    path: '/projects/:project_id',
    name: 'ProjectApp',
    meta: { requiresAuth: true },
    component: () => import('../ProjectApp.vue'),
    children: [
      {
        path: '',
        name: 'TimeLines',
        component: () => import('../views/TimeLinesView.vue')
      },
      {
        path: 'questions',
        name: 'ProjectQuestions',
        component: () => import('../views/QuestionsView.vue')
      },
      {
        path: 'question/:question_id',
        name: 'ProjectQuestion',
        component: () => import('../views/QuestionView.vue')
      },
      {
        path: 'users',
        name: 'ProjectUsers',
        component: () => import('../views/ProjectUsers.vue'),
      },
      {
        path: 'learning_course_list',
        name: 'ProjectLearningCourseList',
        meta: { requiresAuth: true },
        component: () => import('../views/LearningCourseListView.vue')
      },
      {
        path: 'daily_learning',
        name: 'ProjectDailyLearning',
        meta: { requiresAuth: true },
        component: () => import('../views/DailyLearning.vue')
      },
      {
        path: 'daily_setting',
        name: 'ProjectDailySetting',
        meta: { requiresAuth: true },
        component: () => import('../views/DailySettingView.vue')
      },
      {
        path: 'learning_course/:learning_course_id',
        name: 'ProjectLearningCourse',
        meta: { requiresAuth: true },
        component: () => import('../views/LearningCourse.vue')
      },
      {
        path: 'learning_course_history/:learning_course_id',
        name: 'ProjectLearningCourseHistory',
        meta: { requiresAuth: true },
        component: () => import('../views/LearningCourseHistory.vue')
      },
      {
        path: 'learning_course_show/:learning_course_id',
        name: 'ProjectLearningCourseShow',
        meta: { requiresAuth: true },
        component: () => import('../views/LearningCourseShow.vue')
      },
      {
        path: 'learning_course_edit/:learning_course_id',
        name: 'ProjectLearningCourseEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/LearningCourseEdit.vue')
      },
      {
        path: 'learning_course_new/:category_id',
        name: 'ProjectLearningCourseNew',
        meta: { requiresAuth: true },
        component: () => import('../views/LearningCourseNew.vue')
      },
      {
        path: 'call_records',
        name: 'ProjectCallRecords',
        component: () => import('../views/CallRecords.vue'),
        children: [
          {
            path: ':call_record_id',
            name: 'ProjectCallRecordDetail',
            component: () => import('../views/CallRecordDetail.vue'),
          },
        ],
      },
      {
        path: 'report',
        name: 'ProjectReport',
        component: () => import('../views/ReportView.vue')
      },
      {
        path: 'posts',
        name: 'Posts',
        component: () => import('../views/PostsView.vue')
      },
      {
        path: 'post_create',
        name: 'PostCreate',
        component: () => import('../views/PostCreateView.vue')
      },
      {
        path: 'post/:post_id',
        name: 'Post',
        component: () => import('../views/PostView.vue')
      },
      {
        path: 'post_edit/:post_id',
        name: 'PostEdit',
        component: () => import('../views/PostEditView.vue')
      },
      {
        path: 'time_line_create',
        name: 'TimeLineCreate',
        component: () => import('../views/TimeLineCreateView.vue')
      },
      {
        path: 'time_line/:time_line_id',
        name: 'TimeLine',
        component: () => import('../views/TimeLineView.vue')
      },
      {
        path: 'time_line_edit/:time_line_id',
        name: 'TimeLineEdit',
        component: () => import('../views/TimeLineEditView.vue')
      },
      {
        path: 'question_search_result',
        name: 'ProjectQuestionSearchResult',
        component: () => import('../views/ReportQuestionSearchResultView.vue')
      },
      {
        path: 'daily_learning_report',
        name: 'ProjectDailyLearningReport',
        component: () => import('../views/ReportDailyLearningView.vue')
      },
      {
        path: 'user_learning_report',
        name: 'UserLearningReportIndex',
        component: () => import('../views/ReportUserLearningView.vue'),
        children: [
          {
            path: ':user_id',
            name: 'UserLearningReport',
            component: () => import('../views/ReportUserLearningView.vue'),
          }
        ]
      },
      {
        path: 'user_last_access_report',
        name: 'ProjectUserLastAccessReport',
        component: () => import('../views/ReportProjectUserLastAccessView.vue')
      },
      {
        path: 'iine_ranking',
        name: 'IineRanking',
        component: () => import('../views/IineRanking.vue')
      },
      {
        path: 'favorite_ranking',
        name: 'FavoriteRanking',
        component: () => import('../views/FavoriteRanking.vue')
      },
      {
        path: 'view_ranking',
        name: 'ViewRanking',
        component: () => import('../views/ViewRanking.vue')
      },
      {
        path: 'question_created_ranking',
        name: 'QuestionCreatedRanking',
        component: () => import('../views/QuestionCreatedRanking.vue')
      },
      {
        path: 'commented_question_list',
        name: 'CommentedQuestionList',
        component: () => import('../views/CommentedQuestionList.vue')
      },
      {
        path: 'import',
        name: 'ProjectImport',
        component: () => import('../views/ProjectImport.vue'),
      },
      {
        path: 'alert_forms',
        name: 'ProjectAlertFormList',
        meta: { requiresAuth: true },
        component: () => import('../views/ProjectAlertFormList.vue')
      },
      {
        path: 'alert_forms/:project_alert_form_id',
        name: 'ProjectAlertForm',
        meta: { requiresAuth: true },
        component: () => import('../views/ProjectAlertForm.vue')
      },
      {
        path: 'alert_forms/:project_alert_form_id/input',
        name: 'InputProjectAlertForm',
        meta: { requiresAuth: true },
        component: () => import('../views/InputProjectAlertForm.vue')
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: "/:catchAll(.*)",
    component: () => import('../views/errors/NotFound'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(authorizeToken)

export default router
