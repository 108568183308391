import { reactive } from "vue";
import { Question } from "@/types/question"
import { QuestionCategory } from "@/types/question_category"
import { QuestionHistory } from "@/types/question_history"
type State = {
    projectId: number;
    question: Question | null;
    questions: Question[];
    questionCategories: QuestionCategory[];
    questionCategoryId: number;
}
export const QusitionState = {
    state: reactive<State>({
        projectId: 0,
        question: null,
        questions: [],
        questionCategories: [],
        questionCategoryId: 0,
    }),
    setProjectId(projectId: number) {
        this.state.projectId = projectId;
        // project_idが変更されたので全部クリアする
        this.state.question = null;
        this.state.questions = [];
        this.state.questionCategories = [];
        this.state.questionCategoryId = 0;
    },
    add(question: any) {
        this.state.questions.unshift(question);
    },
    addQuestions(questions: Question[]) {
        for (const q of questions) {
            this.state.questions.push(q);
        }
    },
    clearNotice(question_id: number) {
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].id == question_id) {
                this.state.questions[i].notice_comment = false;
                break;
            }
        }
    },
    setNotice(question_id: number) {
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].id == question_id) {
                this.state.questions[i].notice_comment = true;
                break;
            }
        }
    },
    // addQuestionCategory(questionCategory: QuestionCategory, parent_id: number) {
    //     if (parent_id == 0) {
    //         this.state.questionCategories.push(questionCategory);
    //     } else {
    //         const parent_q_category = this.searchQuestionCategory(this.state.questionCategories, parent_id);
    //         if (parent_q_category) {
    //             if (!parent_q_category.children) {
    //                 parent_q_category.children = []
    //             }
    //             parent_q_category.children.push(questionCategory)

    //         }

    //     }

    //     // this.state.questionCategories.push(question_category);
    // },
    updateQuestionCategory(question_category: QuestionCategory) {
        const target_question_category = this.searchQuestionCategory(this.state.questionCategories, question_category.id);
        if (target_question_category) {
            target_question_category.name = question_category.name;
        }
    },
    searchQuestionCategory(q_categories: QuestionCategory[], search_id: number): QuestionCategory | null {
        for (const q of q_categories) {
            if (q.id == search_id) {
                return q
            }
            if (q.children && q.children.length > 0) {
                const ret = this.searchQuestionCategory(q.children, search_id);
                if (ret != null) {
                    return ret;
                }
            }
        }
        return null;
    },
    setQuestionCategories(questionCategories: QuestionCategory[]) {
        this.state.questionCategories = [];
        for (const question_category of questionCategories) {
            this.state.questionCategories.push(question_category);
        }
    },

    set(question: Question | null) {
        // let q = new Question();
        // q.body = "hoge";
        this.state.question = question;
    },
    setQuestionFavorite(question_id: number, favorite: boolean) {
        if (this.state.question != null && this.state.question.id == question_id) {
            this.state.question.favorite = favorite;
        }
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].id == question_id) {
                this.state.questions[i].favorite = favorite;
                break;
            }
        }

    },
    setQuestionCategory(questionCategoryId: number) {
        this.state.questionCategoryId = questionCategoryId;
    },
    // すでに設定されてるIDと同じ場合に更新する
    update(question: Question, listset = false) {
        if (this.state.question && this.state.question.id == question.id) {
            this.state.question = question;
        }

        if (listset == false) {
            return true;
        }
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].id == question.id) {
                this.state.questions[i] = question;
                break;
            }
        }
    },
    remove(question_id: number) {
        if (this.state.question != null && this.state.question.id == question_id) {
            this.state.question = null;
        }
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].id == question_id) {
                this.state.questions.splice(i, 1);
                break;
            }
        }
    },
    addHistory(question_id: number, history: QuestionHistory) {
        if (this.state.question && question_id == this.state.question.id) {
            this.state.question.question_histories.unshift(history);
            this.state.question.comment_count += 1
        }
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].id == question_id) {
                this.state.questions[i].comment_count += 1;
                break;
            }
        }
    },
    updateHistory(history: QuestionHistory) {
        if (this.state.question == null) {
            return;
        }
        if (history.question_id != this.state.question.id) {
            return;
        }
        for (let i = 0; i < this.state.question.question_histories.length; i++) {
            if (this.state.question.question_histories[i].id == history.id) {
                this.state.question.question_histories[i] = history

            }
        }
    },
    removeHistory(question_id: number, history_id: number) {
        // リストに表示されてるコメントIDの更新
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].id == question_id) {
                this.state.questions[i].comment_count -= 1;
                break;
            }
        }
        if (question_id != this.state.question?.id) {
            return;
        }
        this.state.question.comment_count -= 1;
        for (let i = 0; i < this.state.question.question_histories.length; i++) {
            if (this.state.question.question_histories[i].id == history_id) {
                this.state.question.question_histories.splice(i, 1)
            }
        }
    },


}


