import { onMounted, ref, Ref, watch } from 'vue'
import { ProjectRepository } from '@/repositories/ProjectRepository'
import { Project } from '@/types/project'
import axios from 'axios';
import { ElMessage } from "element-plus"

const repository = new ProjectRepository();

export const useProjectList = () => {
  const projects: Ref<Project[]>  = ref<Project[]>([]);
  const loading: Ref<boolean> = ref<boolean>(false);
  const errorMessage: Ref<string> = ref<string>('');

  onMounted(async () => {
    try {
      loading.value = true;
      projects.value = await repository.getUserProjetList();
      loading.value = false;
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response) {
        errorMessage.value = `モニタリング情報のロード時にエラーが発生しました。(status: ${e.response.status})`;
      }
    }
  });

  watch(errorMessage, () => {
    if (errorMessage.value == "") {
      return;
    }
    ElMessage({
      showClose: true,
      message: errorMessage.value,
      type: 'error'
    });
    errorMessage.value = "";
  });

  return {
    projects,
    loading,
  };
};
