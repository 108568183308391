import axios from "axios";
import { Project } from '@/types/project'

export class ProjectRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;

  async get(id: number): Promise<Project> {
    return axios.get(ProjectRepository.DOMAIN + "v1/projects/" + id).then((response) => {
      return response.data;
    });
  }

  async getList(): Promise<Project[]> {
    return axios.get(ProjectRepository.DOMAIN + "v1/projects").then((response) => {
      return response.data.projects;
    }, (error) => {
      console.log(error);
      return [];
    });
  }
  async getDeletedList(): Promise<Project[]> {
    return axios.get(ProjectRepository.DOMAIN + "v1/projects/deleted_list").then((response) => {
      return response.data.projects;
    }, (error) => {
      console.log(error);
      return [];
    });
  }
  async getUserProjetList(): Promise<Project[]> {
    return axios.get(ProjectRepository.DOMAIN + "v1/projects/user_projects").then((response) => {
      return response.data.projects;
    }, (error) => {
      console.log(error);
      return [];
    });
  }

  async add(name: string): Promise<void> {
    return axios.post(ProjectRepository.DOMAIN + "v1/projects", { project: { name: name } }).then(null, (error) => {
      // TODO
      console.log(error)
    });
  }

  async update(project: Project): Promise<void> {
    return axios.put(ProjectRepository.DOMAIN + "v1/projects/" + project.id, { project: { name: project.name } }).then(null, (error) => {
      // TODO
      console.log(error)
    });
  }

  async delete(project: Project): Promise<void> {
    return axios.delete(ProjectRepository.DOMAIN + "v1/projects/" + project.id).then(null, (error) => {
      // TODO
      console.log(error)
    })
  }
  async restoration(project: Project): Promise<void> {
    return axios.post(ProjectRepository.DOMAIN + "v1/projects/" + project.id + "/restoration", {}).then(null, (error) => {
      // TODO
      console.log(error)
    });
  }

  async getNotices(): Promise<{ [key: number]: string[] }> {
    return axios.get(ProjectRepository.DOMAIN + "v1/projects/user_notices").then((response) => {
      return response.data.notices;
    }, (error) => {
      console.log(error);
      return [];
    });

  }
}
