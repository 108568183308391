import { createApp } from 'vue'
import App from './App.vue'
import '@/styles/element/index.scss'
import ElementPlus from 'element-plus';
import ja from 'element-plus/es/locale/lang/ja'
import router from './router'
import store from './store'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGtag from "vue-gtag";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faThumbsUp, faStar } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp as farThumbsUp, faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import VueScrollTo from 'vue-scrollto'

const app = createApp(App)

axios.interceptors.request.use((config) => {
  // config.headers['X-CSRF-Token'] = csrfToken()
  config.headers.client = window.localStorage.getItem('client');
  config.headers['access-token'] = window.localStorage.getItem('access-token');
  config.headers.uid = window.localStorage.getItem('uid');
  // config.headers['token-type'] = window.localStorage.getItem('token-type');
  return config;
});

app.component('fa', FontAwesomeIcon)
library.add(faThumbsUp, farThumbsUp, faStar, farStar);
app.use(router)
app.use(store)
app.use(ElementPlus, {
  locale: ja,
})
app.use(VueScrollTo)
// app.use(store)
if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
  app.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
  }, router);
}
app.mount('#app')
// createApp(App).use(router).mount('#app')
