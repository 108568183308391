
import Header from "@/components/Header.vue";
import { computed, onMounted, onBeforeUnmount, watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { ElMessageBox } from "element-plus"
import ActionCable from "actioncable";
import { ProjectRepository } from '@/repositories/ProjectRepository'
import { NoticeState } from "@/state/NoticeState";
import { QusitionState } from "@/state/QusitionState";
export default {
  name: "Home",
  components: {
    Header,
  },
  setup() {
    const store = useStore();
    let userChannel: any = null;
    let version = ""
    let reloadDailogVisivle = false
    const repository = new ProjectRepository();
    watch(() => store.state.login.user_id, (user_id) => {
      if (user_id > 0) {
        socketConnect();
      } else {
        // disconnect
        if (userChannel != null) {
          userChannel.unsubscribe();
        }
      }
    });
    const checkUpdate = () => {
      let url = process.env.VUE_APP_ROOT_DOMAIN + 'version.text'
      const instance = axios.create()
      instance.get(url).then((response) => {
        if (version != '' && version != response.data) {
          if (reloadDailogVisivle == true) {
            return
          }
          reloadDailogVisivle = true;
          ElMessageBox.confirm(
            "アプリケーションに更新がありました。更新をしますか？(更新をしない場合正常に動作しない可能性があります)",
            "更新があります",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          )
            .then(() => {
              location.reload();
            })
            .catch(() => {
              reloadDailogVisivle = false;
            });
        } else {
          version = response.data;
        }

      });
    }
    const socketConnect = () => {
      let weDomain = process.env.VUE_APP_WS_DOMAIN;
      const cable = ActionCable.createConsumer(weDomain);
      if (userChannel != null) {
        userChannel.unsubscribe();
      }
      if (store.state.login.user_id == 0) {
        return;
      }
      userChannel = cable.subscriptions.create(
        { channel: "UserChannel", user_id: store.state.login.user_id },
        {
          connected() {
            // console.log("connected");
          },
          disconnected() {
            // console.log("disconnected");
          },
          rejected() {
            // console.log("rejected");
          },
          received(data: any) {
            updateNotices();
            switch (data.message) {
              case "create_question_comment":
                QusitionState.setNotice(data.id)
                break;
              case "show_question":
                QusitionState.clearNotice(data.id)
                break;
            }
          },
        }
      ); 
    }
    const updateNotices = async() => {
      const notices = await repository.getNotices();
      for (let key in notices) {
        NoticeState.set(Number(key), notices[key])
      }

    }

    onMounted(() => {
      checkUpdate()
      setInterval(() => {
        checkUpdate();
      }, 1000 * 60);
      updateNotices();
      socketConnect();
      
 
    })
    onBeforeUnmount(() => {
      if (userChannel != null) {
        userChannel.unsubscribe();
      }
    });


    return { loading: computed(() => store.state.login.check_auth_loading) };
  },
};
