import { reactive } from "vue";

type State = {
  selectCategory: number[];
  latestSelectCategory: number;
}

export const SelectCategoryState = {
  state: reactive<State>({
    selectCategory: [],
    latestSelectCategory: 0,
  }),
  clear() {
    this.state.selectCategory = [];
    this.state.latestSelectCategory = 0;
    localStorage.removeItem('selectCategory');
    localStorage.removeItem('latestSelectCategory');
  },
  set(select_category: number) {
    for(let i = 0; i < this.state.selectCategory.length; i++){
      if(this.state.selectCategory[i] == select_category){
        return
      }
    }
    this.state.selectCategory.push(select_category);
    localStorage.setItem('selectCategory', JSON.stringify(this.state.selectCategory))
    const categoryId = JSON.parse(localStorage.getItem('selectCategory') as string) as number[];
    return categoryId
  },
  delete(select_category: number) {
    for(let i = 0; i < this.state.selectCategory.length; i++){
      if(this.state.selectCategory[i] == select_category){
        this.state.selectCategory.splice(i, 1);
      }
    }
    localStorage.setItem('selectCategory', JSON.stringify(this.state.selectCategory));
    const categoryId = JSON.parse(localStorage.getItem('selectCategory') as string) as number[];
    return categoryId
  },
  selectedCategoryAndlatestCategorySet() {
    const categoryId = JSON.parse(localStorage.getItem('selectCategory') as string) as number[];
    if(categoryId){
      this.state.selectCategory = categoryId;
    }
    const latestCategoryId = Number(localStorage.getItem('latestSelectCategory'));
    this.state.latestSelectCategory = latestCategoryId;
  },
  latestCategoryIdSet(category_id: number) {
    this.state.latestSelectCategory = category_id
    localStorage.setItem('latestSelectCategory', String(this.state.latestSelectCategory) )
  },
  getLatestCategoryId() {
    const latestCategoryId = Number(localStorage.getItem('latestSelectCategory'));
    return latestCategoryId
  }
}


