// import axios from 'axios'
import store from '../store'
export const authorizeToken = (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // let domain = process.env.VUE_APP_DOMAIN;
    store
      .dispatch("login/check_auth", { projectId: to.params.project_id })
      .then(
        () => {
          next()
        },
        () => {
          next({ path: '/login' })
        }
      );
  } else {
    next()
  }
}