import axios from "axios";
import { Project } from '@/types/project'
import { User } from '@/types/user'

interface GetListParams {
  project_id?: number;
}

interface UserAddParams {
  login_id: string;
  password: string;
  name: string;
  role: string;
}

interface UserEditParams {
  password: string;
  name: string;
  role: string;
}

export class UserRepository {
  async getList(params: GetListParams = {}): Promise<User[]> {
    return axios.get(process.env.VUE_APP_DOMAIN + "v1/users", { params: params }).then((response: any) => {
      return response.data.users.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          login_id: item.login_id,
          manager_flg: item.manager_flg,
          role: item.manager_flg ? 'manager' : 'member',
        };
      });
    });
  }

  async getDeletedList(params: GetListParams = {}): Promise<User[]> {
    return axios.get(process.env.VUE_APP_DOMAIN + "v1/users/deleted_list", { params: params }).then((response: any) => {
      return response.data.users.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          login_id: item.login_id,
        };
      });
    });
  }

  async getProjects(userId: number): Promise<Project[]> {
    return axios.get(process.env.VUE_APP_DOMAIN + `v1/users/${userId}/projects`).then((response: any) => {
      return response.data.projects.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          notice: item.notice,
        };
      });
    });
  }

  async add(params: UserAddParams): Promise<void> {
    const request_params: { login_id: string, name: string, password: string, manager_flg: boolean } = { ...params, manager_flg: params.role === 'manager' }
    return axios.post(process.env.VUE_APP_DOMAIN + "v1/users", { user: request_params });
  }

  async edit(id: number, params: UserEditParams): Promise<void> {
    const request_params: { name: string, password: string, manager_flg: boolean } = { ...params, manager_flg: params.role === 'manager' }
    return axios.put(process.env.VUE_APP_DOMAIN + "v1/users/" + id, { user: request_params });
  }

  async suspend(id: number): Promise<void> {
    return axios.delete(process.env.VUE_APP_DOMAIN + "v1/users/" + id);
  }

  async restore(id: number): Promise<void> {
    return axios.post(process.env.VUE_APP_DOMAIN + "v1/users/" + id + "/restore");
  }
}
