// import router from "../../router/router"
import axios from 'axios'


const Login = {
    namespaced: true,
    state: {
        login_status: false,
        user_id: 0,
        manager_flg: false,
        project_owner_flg: false,
        check_auth_loading: false,

    },
    mutations: {
        setLoginStatus(state, payload) {
            state.login_status = payload;
        },
        setUserId(state, payload) {
            state.user_id = payload;
        },
        setCheckAuthLoading(state, payload) {
            state.check_auth_loading = payload;
        },
        setManagerFlg(state, payload) {
            state.manager_flg = payload
        },
        setProjectOwnerFlg(state, payload) {
            state.project_owner_flg = payload
        },
    },
    actions: {
        login({ commit }, payload) {
            let domain = process.env.VUE_APP_DOMAIN;
            const params = new URLSearchParams();
            params.append("login_id", payload.login_id);
            params.append("password", payload.password);
            return new Promise((resolve, rejects) => {
                axios.post(domain + "v1/auth/sign_in", params).then((response) => {

                    localStorage.setItem('access-token', response.headers['access-token'])
                    localStorage.setItem('client', response.headers.client);
                    localStorage.setItem('uid', response.headers.uid);
                    localStorage.setItem('expiry', response.headers.expiry);
                    commit('setUserId', response.data.data.id);
                    commit('setLoginStatus', true)
                    resolve(response)

                }).catch((error) => {
                    rejects(error)
                })
            })
        },
        logout({ commit }) {
            let domain = process.env.VUE_APP_DOMAIN;
            return new Promise((resolve, rejects) => {
                axios.delete(domain + "v1/auth/sign_out", {}).then((response) => {

                    localStorage.removeItem('access-token');
                    localStorage.removeItem('client');
                    localStorage.removeItem('uid');
                    localStorage.removeItem('expiry');
                    commit('setUserId', 0);
                    commit('setLoginStatus', false)
                    resolve(response)
                }, (error) => {
                    rejects(error)
                })
            });
        },
        check_auth({ commit }, { projectId }) {
            let domain = process.env.VUE_APP_DOMAIN;
            commit('setCheckAuthLoading', true)
            return new Promise((resolve, rejects) => {
                axios.get(domain + "v1/users/validate_token", { params: { project_id: projectId } }).then((response) => {
                    commit('setLoginStatus', true);
                    commit('setUserId', response.data.user_id);
                    commit('setManagerFlg', response.data.manager_flg);
                    commit('setProjectOwnerFlg', response.data.project_owner_flg);
                    commit('setCheckAuthLoading', false);
                    // router.push("/login")
                    // console.log(response)
                    resolve(response)
                }, (error) => {
                    commit('setLoginStatus', false);
                    commit('setUserId', 0);
                    commit('setManagerFlg', false);
                    commit('setProjectOwnerFlg', false);
                    commit('setCheckAuthLoading', false)
                    rejects(error)


                })
            });
        },
    }
}
export default Login

