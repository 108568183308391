
import axios from 'axios'
function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
export default {
    // 設定から有効にする
    setPushServiceUser: function () {
        // navigator.serviceWorker.ready.then(registration => {
        return navigator.serviceWorker.getRegistration().then(function (reg) {
            if (!reg.active) {
                return;
            }
            if (reg.active.state !== "activated") {
                return;
            }
            return reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlB64ToUint8Array(process.env.VUE_APP_WEB_PUSH_PUB_KEY), // VAPIDで使用するサーバ公開鍵
            }).then(subscription => {
                var params = {
                    subscription: subscription.toJSON()
                }
                return axios.post(process.env.VUE_APP_DOMAIN + "v1/devices", params)
            });
        });

    },
    setPushService: function () {
        if (navigator.serviceWorker) {

            navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
                .then(function (reg) {
                    var serviceWorker;
                    if (reg.installing) {
                        serviceWorker = reg.installing;
                    } else if (reg.waiting) {
                        serviceWorker = reg.waiting;
                    } else if (reg.active) {
                        serviceWorker = reg.active;
                    }
                    if (serviceWorker) {
                        serviceWorker.addEventListener("statechange", function (e) {
                            if (e.target.state == "activated") {
                                reg.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: urlB64ToUint8Array(process.env.VUE_APP_WEB_PUSH_PUB_KEY), // VAPIDで使用するサーバ公開鍵
                                }).then(function (subscription) {
                                    // API叩いてDBに登録するなどの処理
                                    var params = {
                                        subscription: subscription.toJSON()
                                    }
                                    axios.post(process.env.VUE_APP_DOMAIN + "v1/devices", params)
                                    return;
                                })
                            }
                        });
                    }

                });
        }
    },
    // ブラウザの通知を外すでもunsubcribeできるのでコメントアウト
    // webPushUnSubscribe: function () {
    //     return navigator.serviceWorker.getRegistration().then(function (registration) {
    //         return registration.pushManager.getSubscription().then(function (subscription) {
    //             if (!subscription) {
    //                 return;
    //             }
    //             return subscription.unsubscribe().then(function (success) {
    //                 // API叩いて無効にするなどの処理
    //                 // API叩いてDBに登録するなどの処理
    //                 var params = {
    //                     subscription: subscription.toJSON()
    //                 }
    //                 console.log(params)
    //                 axios.delete("/devices/un_subscribe", params)
    //                 return;
    //             })["catch"](function (error) {
    //                 return console.log(error);
    //             });
    //         });
    //     });
    // },
    webPushStatus: function () {
        return Notification.permission;
    }
}