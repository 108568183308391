import { reactive } from "vue";
type State = {
  notices: { [key: number]: string[] }
}
export const NoticeState = {
  state: reactive<State>({
    notices: {},
  }),
  clear() {
    this.state.notices = {}
  },
  set(project_id: number, notices: string[]) {
    this.state.notices[project_id] = notices;
  },
  get(project_id: number): string[] {
    if (this.state.notices[project_id] != undefined) {
      return this.state.notices[project_id]
    }
    return []
  },
  otherNotice(project_id: number): boolean {
    for (const pid in this.state.notices) {
      if (Number(pid) == project_id) {
        continue;
      }
      if (this.state.notices[pid] != undefined && this.state.notices[pid].length > 0) {
        return true;
      }
    }
    return false
  }
}


